var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-container"},[_c('MaxWidthSection',[_c('v-row',{staticClass:"py-4 py-md-8"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('GritifyLogo',{staticClass:"d-block mb-5",attrs:{"color":"white"}})],1),_vm._v(" "),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12","sm":"6","md":"3"}}),_vm._v(" "),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12","sm":"6","md":"3"}}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(!_vm.excludeLanguageSwitch)?[_c('LanguageSwitcher')]:_vm._e()],2)],1),_vm._v(" "),_c('div',{staticClass:"white--text"},[_c('v-row',[_c('v-col',{staticClass:"pb-8 pb-sm-12 pb-md-16",attrs:{"cols":"6","md":"3"}},[_c('div',{staticClass:"gr-heading-small"},[_vm._v("\n            "+_vm._s(_vm.$t("Kontakt"))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"gr-body-medium"},[_vm._v("\n            Gritify AB\n            "),_c('br'),_vm._v("\n            Riddargatan 23\n            "),_c('br'),_vm._v("\n            114 57 Stockholm\n          ")]),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_vm._v("\n            info@gritify.io\n          ")])]),_vm._v(" "),_c('v-col',{staticClass:"pb-8 pb-sm-12 pb-md-16",attrs:{"cols":"6","md":"3"}},[_c('div',{staticClass:"gr-heading-small"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'talentStart',
                })}},[_vm._v("\n              "+_vm._s(_vm.$t("För talanger"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.goToPath(
                  _vm.localePath({
                    name: 'talentStart',
                    hash: '#howGritifyWorksForTalents',
                  })
                )}}},[_vm._v("\n              "+_vm._s(_vm.$t("Så funkar Gritify för talanger"))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'talentStart-createProfileStart',
                })}},[_vm._v(_vm._s(_vm.$t("Skapa anonym profil"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.goToPath(
                  _vm.localePath({
                    name: 'talentStart',
                    hash: '#commonQuestions',
                  })
                )}}},[_vm._v("\n              "+_vm._s(_vm.$t("Vanliga frågor talang"))+"\n            ")])])]),_vm._v(" "),_c('v-col',{staticClass:"pb-8 pb-sm-12 pb-md-16",attrs:{"cols":"6","md":"3"}},[_c('div',{staticClass:"gr-heading-small"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'companyStart',
                })}},[_vm._v("\n              "+_vm._s(_vm.$t("För företag"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'companyStart-directaccess',
                })}},[_vm._v("\n              "+_vm._s(_vm.$t("Direktaccess till kandidatmarknaden"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'companyStart-passiveCandidates',
                })}},[_vm._v("\n              "+_vm._s(_vm.$t("Få tillgång till passiva kandidater"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'companyStart-fordomsfrihet',
                })}},[_vm._v("\n              "+_vm._s(_vm.$t("Koppla sömlöst på fördomsfrihet"))+"\n            ")])],1)]),_vm._v(" "),_c('v-col',{staticClass:"pb-8 pb-sm-12 pb-md-16",attrs:{"cols":"6","md":"3"}},[_c('div',{staticClass:"gr-heading-small"},[_vm._v("\n            "+_vm._s(_vm.$t("Om Gritify"))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'aboutgritify',
                })}},[_vm._v(_vm._s(_vm.$t("Organisation"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                  name: 'pressreleases',
                })}},[_vm._v("\n              "+_vm._s(_vm.$t("Press & nyheter"))+"\n            ")])],1)])],1),_vm._v(" "),_c('div',{staticClass:"d-sm-none pb-8"},[_c('div',{staticClass:"gr-body-mini mb-n2"},[_vm._v("\n          "+_vm._s(_vm.$t("Följ Gritify:"))+"\n        ")]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"gr-heading-mini"},[_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://www.linkedin.com/company/gritifyio"}},[_vm._v("\n                "+_vm._s(_vm.$t("Linkedin"))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://www.facebook.com/gritify.io/"}},[_vm._v("\n                "+_vm._s(_vm.$t("Facebook"))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://www.instagram.com/gritify.io/"}},[_vm._v("\n                "+_vm._s(_vm.$t("Instagram"))+"\n              ")])])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                    name: 'privacypolicy',
                  })}},[_vm._v("\n                "+_vm._s(_vm.$t("Integritetspolicy"))+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                    name: 'cookies',
                  })}},[_vm._v("\n                "+_vm._s(_vm.$t("Cookies"))+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                    name: 'sitemap',
                  })}},[_vm._v("\n                "+_vm._s(_vm.$t("Sitemap"))+"\n              ")])],1)])],1)],1),_vm._v(" "),_c('div',{staticClass:"d-none d-sm-flex align-center mb-8"},[_c('div',{staticClass:"gr-body-mini mb-n2"},[_vm._v("\n          "+_vm._s(_vm.$t("Följ Gritify:"))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"ms-5 gr-heading-mini"},[_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://www.linkedin.com/company/gritifyio"}},[_vm._v("\n            "+_vm._s(_vm.$t("Linkedin"))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"ms-5 gr-heading-mini"},[_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://www.facebook.com/gritify.io/"}},[_vm._v("\n            "+_vm._s(_vm.$t("Facebook"))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"ms-5 gr-heading-mini"},[_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://www.instagram.com/gritify.io/"}},[_vm._v("\n            "+_vm._s(_vm.$t("Instagram"))+"\n          ")])]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"ms-5 gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                name: 'privacypolicy',
              })}},[_vm._v("\n            "+_vm._s(_vm.$t("Integritetspolicy"))+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"ms-5 gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                name: 'cookies',
              })}},[_vm._v("\n            "+_vm._s(_vm.$t("Cookies"))+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"ms-5 gr-heading-mini"},[_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.localePath({
                name: 'sitemap',
              })}},[_vm._v("\n            "+_vm._s(_vm.$t("Sitemap"))+"\n          ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }