






























































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import MaxWidthSection from "../common/MaxWidthSection.vue";
import LanguageSwitcher from "../LanguageSwitcher.vue";
import GritifyLogo from "../styling/GritifyLogo.vue";

@Component({
  components: {
    GritifyLogo,
    MaxWidthSection,
    LanguageSwitcher,
  },
})
export default class GrPublicFooter extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly excludeLanguageSwitch!: boolean;

  goToAnchor(_v: string) {
    this.$vuetify.goTo(0);
    // this.$vuetify.goTo(v, { offset: -104, duration: 200 });
  }

  async goToPath(path: string) {
    if (this.$route.hash) {
      await this.$router.replace(path.split("#")[0]!);
    }
    this.$router.replace(path);
  }

  getIsActive(routeName: string): boolean {
    const fixedName = routeName + "_";
    if (!fixedName) {
      return false;
    }

    return this.$route.name ? this.$route.name?.includes(fixedName) : false;
  }
}
