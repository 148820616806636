














import { Vue, Component } from "nuxt-property-decorator";
// eslint-disable-next-line import/named
import { LocaleObject } from "nuxt-i18n";

@Component
export default class LanguageSwitcher extends Vue {
  isLocaleObject(l: LocaleObject | string): l is LocaleObject {
    return typeof l !== "string" && !!l.code;
  }

  localeChanged(code: string) {
    this.$router.push(this.switchLocalePath(code));
  }

  get selectedLocale() {
    return this.$i18n.locale;
  }

  get availableLocales(): LocaleObject[] {
    return this.$i18n.locales as LocaleObject[];
  }
}
